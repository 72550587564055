<template>
  <div class="wrapper">
    <loader :isLoader="showLoader"></loader>
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar />
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <profile-header />
          </div>
        </div>

        <page-heading-card
          title="Payment Detail"
          subTitle=""
          imageName="billing.png"
        />

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Payment method
          </div>

          <collapse-wrapper title="Card details" v-model="cardDetailsCollapse">
            <div class="card-body collapsed-shipment">
              <div class="row">
                <div class="col-md-4 payment-card-img">
                  <img src="../assets/img/card-detail.png" height="180px" />
                </div>
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th scope="col">Card Type</th>
                          <th scope="col">Card Number</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="cardList.length == 0">
                          <td class="text-center" colspan="4">No card found</td>
                        </tr>
                        <tr
                          v-else
                          v-for="(cardItem, index) in cardList"
                          :key="index"
                        >
                          <th scope="row">
                            <img
                              class="avatar payment-card-image"
                              :src="getImgUrl(cardItem.brand)"
                            />
                          </th>
                          <td>
                            <span class="d-block">
                              {{ "xxxxxxxxxx" + cardItem.last4 }}
                              <span
                                v-if="cardItem.isPrimary"
                                class="badge badge-success"
                                >Default</span
                              >
                            </span>
                            <small class="text-muted">
                              Exp:
                              {{ cardItem.exp_month + "/" + cardItem.exp_year }}
                            </small>
                          </td>
                          <td class="text-center">
                            <base-switch
                              type="success"
                              offText=""
                              onText=""
                              v-model="cardItem.isPrimary"
                              @input="updatePrimaryCard(cardItem)"
                              :isDisabled="cardItem.isPrimary"
                            >
                            </base-switch>
                          </td>
                          <td>
                            <base-button
                              type="primary"
                              class="btn-theme-EFEBEB remove-card-action"
                              @click="showConfirmDeleteCardModal(cardItem)"
                            >
                              <img src="../assets/img/home/trash.png" />
                            </base-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <base-button
                    type="primary"
                    class="btn-theme-24876E btn-width-100"
                    @click="showAddCardModal"
                  >
                    <img src="../assets/img/home/plus-round.png" />
                    Add new card
                  </base-button>
                </div>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper
            title="Email address"
            v-model="emailAddressCollapse"
          >
            <div class="card-body collapsed-shipment">
              <div
                v-for="(item, index) in invoiceEmails"
                :key="index"
                class="row"
              >
                <div class="col-7 col-md-9">
                  <base-input
                    name="email"
                    type="email"
                    label="Invoices will be sent to this email"
                    class="input-v1"
                    v-model="item.email"
                  ></base-input>
                </div>
                <div class="col-5 col-md-3 add-delete-btn text-right">
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB billing-action-left"
                    @click="deleteEmail(index)"
                  >
                    <img src="../assets/img/home/trash.png" />
                  </base-button>
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB billing-action-right"
                    @click="addEmail"
                  >
                    <img src="../assets/img/plus.png" />
                  </base-button>
                </div>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper
            title="Billing address"
            v-model="billingAddressCollapse"
          >
            <div class="card-body collapsed-shipment">
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    name="address"
                    type="text"
                    label="Address number and street name"
                    class="input-v1"
                    v-model="address"
                  ></base-input>
                </div>
                <div class="col-md-6">
                  <base-input
                    name="city"
                    type="text"
                    v-model="city"
                    label="City name"
                    class="input-v1"
                  ></base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    name="zipCode"
                    type="text"
                    v-model="zipCode"
                    label="POSTAL/ZIP Code"
                    class="input-v1"
                  ></base-input>
                </div>
                <div class="col-md-4 form-group has-label">
                  <label class="mb-0">Country</label>
                  <choices-single
                    :options="countryList"
                    v-model="selectedCountry"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                </div>
                <div class="col-md-4 form-group has-label">
                  <label class="mb-0">Province/State</label>
                  <choices-single :options="stateList" v-model="selectedState">
                    <option value="0">Select</option>
                  </choices-single>
                </div>
              </div>
            </div>
          </collapse-wrapper>
        </div>

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Billing History
          </div>

          <div>
            <v-app id="inspire">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="tableData"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :single-select="singleSelect"
                :hide-default-footer="true"
                sort-by="id"
                select-enable
                class="elevation-1"
              >
                <template v-slot:item.status="{ item }">
                  <div v-if="item.status == 'Failed'">
                    <span
                      class="badge mr-2 badge-danger failed-badge badge-pill"
                    >
                      {{ item.status }}
                    </span>
                  </div>
                  <div v-if="item.status == 'Paid'">
                    <span class="badge mr-2 badge-success badge-pill">
                      {{ item.status }}
                    </span>
                  </div>
                </template>

                <template v-slot:item.amount="{ item }">
                  <div>
                    {{ item.amount }}
                    <div>CAD</div>
                  </div>
                </template>

                <template v-slot:item.actions="{}">
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB action-left"
                  >
                    <img src="../assets/img/home/eye.png" />
                  </base-button>
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB action-right"
                  >
                    <img src="../assets/img/home/download.png" />
                  </base-button>
                </template>
              </v-data-table>
            </v-app>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show.sync="addCardModal"
      modal-classes="modal-dialog-centered add-card-modal"
    >
      <h5 slot="header" class="modal-title" id="modal-title-default">
        Add Card
      </h5>

      <div>
        <template>
          <add-card-form
            @token-received="handleToken"
            @show-loading="showLoading"
          />
        </template>
      </div>
    </modal>

    <modal
      :show.sync="confirmDeleteCardModal"
      modal-classes="modal-dialog-centered confrim-quote-modal"
    >
      <h5
        slot="header"
        class="modal-title confrim-quote-header"
        id="modal-title-default"
      >
        Delete
      </h5>

      <div>
        Are you sure you want to delete this card?
      </div>

      <template slot="footer">
        <base-button class="btn-theme-083C2F" @click="deleteCustomerCard">
          Confirm
        </base-button>
        <base-button
          class="btn-theme-EFEBEB"
          @click="confirmDeleteCardModal = false"
          >Cancel
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import CollapseWrapper from "@/views/components/CollapseWrapper.vue";
import ChoicesSingle from "@/components/SingleSelect";
import Modal from "@/components/Modal.vue";
import AddCardForm from "@/views/common/AddCardForm.vue";
import Loader from "@/layout/Loader";
import { API, masterAPI } from "@/api/API";
import { mapGetters } from "vuex";

export default {
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    CollapseWrapper,
    ChoicesSingle,
    Modal,
    AddCardForm,
    Loader
  },
  data() {
    return {
      activeMenuName: "Billing",
      cardDetailsCollapse: true,
      emailAddressCollapse: true,
      billingAddressCollapse: true,
      name: "",
      ///
      address: "",
      city: "",
      zipCode: "",
      stateList: [],
      selectedState: null,
      countryList: [],
      selectedCountry: null,
      invoiceEmails: [
        {
          id: "",
          email: ""
        }
      ],
      addCardModal: false,
      cardList: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
        // Table headers configuration
        { text: "Invoice", value: "invoice", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Amount", value: "amount", sortable: true },
        { text: "Actions", value: "actions", sortable: true }
      ],
      tableData: [
        // {
        //   invoice:"#0136945",
        //   date:"01 Mar 2024",
        //   status:"Paid",
        //   amount:"$199.00",
        // },
        // {
        //   invoice:"#0116989",
        //   date:"16 Apr 2023",
        //   status:"Failed",
        //   amount:"$398.00",
        // },
      ],
      showLoader: false,
      confirmDeleteCardModal: false,
      selectedCardDetail: null
    };
  },
  computed: {
    ...mapGetters(["userDetail"])
  },
  methods: {
    /**
     * Adds a new email object to the invoiceEmails array.
     * Each email object has an id and an email property.
     */
    addEmail() {
      let emails = {
        id: "",
        email: ""
      };
      this.invoiceEmails.push(emails);
    },
    /**
     * Deletes an email from the invoiceEmails array based on the index.
     * Ensures that at least one email remains in the list.
     *
     * @param {number} index - The index of the email to be deleted.
     */
    deleteEmail(index) {
      if (this.invoiceEmails.length > 1) {
        this.invoiceEmails.splice(index, 1);
      } else {
        this.$toast.error("Atleast one email require");
      }
    },
    /**
     * Retrieves the URL of an image based on the icon name.
     * The images are stored in the '../assets/img/card/' directory.
     *
     * @param {string} iconName - The name of the icon file without extension.
     * @returns {string} - The path to the requested image file.
     */
    getImgUrl(iconName) {
      if (iconName) {
        var images = require.context("../assets/img/card/", false, /\.svg$/);
        return images("./" + iconName + ".svg");
      } else {
        return "";
      }
    },
    /**
     * Resets the name field and shows the modal for adding a new card.
     */
    showAddCardModal() {
      this.name = "";
      this.addCardModal = true;
    },
    /**
     * Shows the confirmation modal for deleting a card and sets the selected card details.
     *
     * @param {Object} card - The card object to be deleted.
     */
    async showConfirmDeleteCardModal(card) {
      this.confirmDeleteCardModal = true;
      this.selectedCardDetail = card;
    },
    /**
     * Deletes the selected customer card after confirmation.
     * Prevents deletion if only one card remains.
     */
    async deleteCustomerCard() {
      if (this.cardList.length <= 1) {
        this.confirmDeleteCardModal = false;
        this.$toast.error("Cannot delete the last card");
      } else {
        this.showLoader = true;

        let cardId = this.selectedCardDetail.id;

        let endPoint = API.API_ENDPOINT.customerCard + "/" + cardId;

        let response = await masterAPI(
          endPoint,
          API.API_METHOD.delete,
          undefined,
          undefined
        );

        this.showLoader = false;
        this.confirmDeleteCardModal = false;

        if (response.data.deleted) {
          this.listCustomerCard();
          this.$toast.success("Card deleted successful");
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Toggles the visibility of the loading spinner.
     *
     * @param {boolean} value - Whether to show or hide the loader.
     */
    showLoading(value) {
      this.showLoader = value;
    },
    /**
     * Handles the Stripe token after a card is added.
     * Sends the token to the backend to create a new card.
     *
     * @param {Object} token - The token object returned from Stripe.
     */
    async handleToken(token) {
      let bodyPayload = JSON.stringify({
        token: token.id
      });

      let response = await masterAPI(
        API.API_ENDPOINT.customerCard,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      this.showLoader = false;

      if (response.status == 200) {
        this.listCustomerCard();
        this.addCardModal = false;
        this.$toast.success("Card added successful");
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ""));
      }
    },
    /**
     * Retrieves the list of customer cards from the backend.
     * Updates the cardList array with the retrieved data.
     */
    async listCustomerCard() {
      this.cardList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.customerCard,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.data) {
        this.cardList = response.data;
      }
    },
    /**
     * Updates the primary card for the customer.
     * @param {Object} cardItem - The card object containing the card details.
     * @param {string} cardItem.id - The ID of the card to be updated.
     * @returns {Promise<void>} - A promise that resolves when the update is complete.
     */
    async updatePrimaryCard(cardItem) {
      this.showLoader = true;

      let cardId = cardItem.id;

      let endPoint = API.API_ENDPOINT.customerCard + "/" + cardId;

      let response = await masterAPI(
        endPoint,
        API.API_METHOD.put,
        undefined,
        undefined
      );

      this.showLoader = false;

      if (response.status == 200) {
        this.listCustomerCard();
        this.$toast.success("Primary card updated successful");
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ""));
      }
    }
  },
  created() {
    this.listCustomerCard();
  }
};
</script>

<style></style>
