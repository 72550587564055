<template>
  <div :class="[isCreateQuote ? 'mb-5 mt-4' : 'mb-4 mt-4']">
    <!-- label for Run Historical Rates -->
    <div v-if="addHistoricalRatesLabel" :class="{'d-none' : isQuotesEdit}">
      <a 
        class="add-pickup-delivery-link"
        @click="openHistoricalRates"
      >
       Run Historical Rates +
      </a>
    </div>

    <!-- Input fields for Historical Rates -->
    <div v-else class="row mb-4">
      <div :class="[isCreateQuote ? 'col-md-6' : 'col-md-12']">
        <div class="date-picker-label">Date</div>
       
        <date-picker 
          placeholder="Select date" 
          class="date-picker" 
          format="MMM DD, YYYY"
          :disabled-date="isVersionedTariffDateDisabled" 
          v-model="localVersionedTariffDate"
          @input="handleVersionedTariffDate"
          :disabled="isQuotesEdit"
        ></date-picker>
      </div>

      <div :class="[isCreateQuote ? 'col-md-6 has-label' : 'col-md-12 has-label mt-3']">
        <label>Carrier</label>

        <div class="tariff-version-multiselect">
         
          <multiselect
            v-model="localSelectedCarriers"
            :options="localCarriersList"
            :multiple="true"
            label="name"
            track-by="name"
            placeholder="Select carriers"
            :searchable="true"
            :close-on-select="false"
            @input="handleMultiselectChange"
            :disabled="isQuotesEdit"
          >
            <!-- Custom option template with checkboxes -->
            <template #option="{ option }">
              <div 
                class="multiselect-item custom-control custom-checkbox"
                @click.stop="toggleSelection(option)"
              >
                <input 
                  :id="option.id"
                  type="checkbox" 
                  :checked="option.selectAll ? isSelectAllChecked : isItemChecked(option)" 
                  class="custom-control-input"
                />                
                <label class="mb-0 custom-control-label multiselect-item-label">
                  {{ option.name }}
                </label>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data: function() {
    return {
      localVersionedTariffDate: new Date(this.historicalRatesDate),
       // Local data property for selected carriers to prevent mutating the prop directly
      localSelectedCarriers: [...this.selectedCarriers],  // Copy the parent value to local data
      localCarriersList: [...this.carriersList],
    }
  },
  watch: {
    carriersList(newVal) {
      // Watch for changes in the prop to update the local data property
      this.localCarriersList = [...newVal]; 
    },

    // Watch for changes in the prop
    historicalRatesDate: {  
      immediate: true,
      handler(newVal) {
        // Update localPickupDate if it exists
        if (newVal && newVal.historicalRatesDate) {
          this.localVersionedTariffDate = new Date(newVal.historicalRatesDate);  // Update local model if prop changes
        }
      }
    },
    // Watch for changes in the prop
    selectedCarriers: { 
      immediate: true,
      handler(newVal) {
        // Update localPickupDate if it exists
        if (newVal ) {
          this.localSelectedCarriers = [...newVal];// Update local model if prop changes
        }
      }
    },
  },
  props: {
    // Component props
    isCreateQuote: {
      type: Boolean,
    },
    addHistoricalRatesLabel: {
      type: Boolean,
    },
    historicalRatesDate: {
      type: [String, Date], 
    },
    selectedCarriers: {
      type: Array,
      default: () => []
    },
    carriersList: {
      type: Array,
      required: true
    },
    isQuotesEdit: {
      type: Boolean,
    }
  },
  computed: {
    // Computed property to determine if the historical rates date should be disabled
    isVersionedTariffDateDisabled() {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to midnight
      return (date) => date > today; // Disable dates after today
    },

    // Check if all carriers are selected (excluding "Select All")
    isSelectAllChecked() {
      return this.localSelectedCarriers.length === this.localCarriersList.filter(item => !item.selectAll).length;
    },

    // Check if an individual carrier is selected
    isItemChecked() {
      return (option) => {
        return this.localSelectedCarriers.some(selected => selected.id === option.id);
      };
    }
    },
  methods: {
    /**
     * Toggle the visibility of historical rates
     */
    openHistoricalRates() {
      // Emit event to update the parent's `addHistoricalRatesLabel`
      this.$emit("update:addHistoricalRatesLabel", false);
    },
    /**
     * Handles the change event for the multiselect component.
     */
    handleMultiselectChange(selectedOptions) {
      this.$emit('update:selectedCarriers', selectedOptions);
    },
    /**
     * Handles the selected versioned tariff date.
     * @param {Date} selectedDate - The date that was selected by the user.
     */
    handleVersionedTariffDate(selectedDate) {
      this.$emit('update:historicalRatesDate', selectedDate);  // Emit the updated value back to the parent
    },
    /**
     * Toggles the selection of a carrier item or the "Select All" option.
     * @param {Object} option - The carrier item or "Select All" option to be toggled.
     */
    toggleSelection(option) {
      if (option.selectAll) {
        if (this.isSelectAllChecked) {
          this.localSelectedCarriers = []; // Deselect all items
        } else {
          const nonSelectAllItems = this.localCarriersList.filter(item => !item.selectAll);
          this.localSelectedCarriers = [...nonSelectAllItems]; // Select all non-"Select All" items
        }
      } else {
        const exists = this.localSelectedCarriers.some(selected => selected.id === option.id);
        if (exists) {
          this.localSelectedCarriers = this.localSelectedCarriers.filter(selected => selected.id !== option.id); // Deselect the item
        } else {
          this.localSelectedCarriers.push(option); // Select the item
        }
      }
      // Emit the updated selectedCarriers to the parent component
      this.$emit('update:selectedCarriers', this.localSelectedCarriers);
    }
  }
}
</script>

<style>
  .tariff-version-multiselect .dropdown {
    width: 289px;
    margin-top: 8px;
  }
   .multiselect-item {
    display: flex;
    align-items: center;
  }
  label.mb-0.custom-control-label.multiselect-item-label {
    line-height: 23px !important;
    color: inherit !important;
  }
</style>