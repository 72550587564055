<template>
  <div>
    <modal :show.sync="showModal">
      <slot name="close-button">
        <button
          type="button"
          class="model-close-btn"
          @click="showModal = false"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span :aria-hidden="true">×</span>
        </button>
      </slot>
      
      <div>
        <div class="mt-3">
          <span class="font-weight-bold data-type-text">Condition Type: </span>
          <span class="fs-13">{{ capitalizeFirstLetter(selectedItem.conditionType) }}</span>
        </div>

        <!-- Render Affects Object -->
        <div class="mb-3 mt-2" v-if="selectedItem?.conditionTypeFields?.affects">
          <span class="font-weight-bold data-type-text">Affects: </span>
          <div class="row pricing-value-header">
            <div v-for="(value, key, keyIndex) in selectedItem.conditionTypeFields.affects" :key="keyIndex" class="col-3 font-weight-bold text-transform-capitalize">
              {{ key }}
            </div>
          </div>
          <div class="row">
            <div v-for="(value, key, keyIndex) in selectedItem.conditionTypeFields.affects" :key="keyIndex" class="col-3 fs-13">
              {{ value }}
            </div>
          </div>
        </div>

        <div class="field-border-top"></div>

        <!-- Render Thresholds Array -->
        <div class="mb-3 mt-2" v-if="selectedItem?.conditionTypeFields?.thresholds">
          <span class="font-weight-bold data-type-text">Thresholds: </span>
          <div v-for="(threshold, index) in selectedItem.conditionTypeFields.thresholds" :key="index" class="mb-3">
            <div class="row pricing-value-header">
              <div v-for="(value, key, keyIndex) in threshold" :key="keyIndex" class="col-3 font-weight-bold text-transform-capitalize">
                {{ key }}
              </div>
            </div>
            <div class="row">
              <div v-for="(value, key, keyIndex) in threshold" :key="keyIndex" class="col-3 fs-13">
                {{ value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
  
<script>
  import Modal from "@/components/Modal.vue";

  export default {
    components: {
      Modal
    },
    data: function() {
      return {
        showModal: false,
      };
    },
    props: {
      selectedItem: {
        type: Object
      }
    },
    methods: {
      /**
       * Opens the modal and sets the missing reasons.
       */
      openModal() {
        this.showModal = true;
      },
      /**
       * Capitalizes the first letter of the given string and converts the rest to lowercase.
       *
       * @param {string} value - The string to capitalize.
       * @returns {string} - The formatted string with the first letter capitalized.
       */
      capitalizeFirstLetter(value) {
        if (!value) return '';
          return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }
    }
  };
</script>

<style></style>
  