<template>
  <div class="wrapper">
    <div class="body-container row d-row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 plr-30 ">
        <div class="row d-row">
          <div class="col-lg-8">
            <div class="mt-4 headline-24">Hi {{ userName }},</div>
            <h2 class="mb-4 headline-48">Welcome back👋</h2>

            <card bodyClasses="p-0" class="card-FAF6EB br-16">
              <template slot="body">
                <div class="container">
                  <div class="row align-items-center ptrl-20">
                    <div class="col-md-6 order-2 order-md-1">
                      <div class="font24-bold headline-24">
                        All your carriers in the same place.
                      </div>
                      <p class="font-14 mt-2 mb-2">
                        Contract and public pricing. Enter shipment details once
                        and rates from all your carriers are returned.
                      </p>
                      <div class="m-text-center mb-4">
                        <router-link to="/newquote">
                          <base-button class="btn btn-theme-083C2F btn-default">
                            Create Quote
                          </base-button>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-md-6 order-1 order-md-2 m-text-center">
                      <img
                        class="img-width-100"
                        src="../assets/img/home/image1.png"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </card>

            <div class="row">
              <!--
              <div class="col-md-6 order-2 order-md-1">
                <record-count-card
                  :cardData="cardData"
                />
              </div>
              <div class="col-md-6 order-1 order-md-2">
                <div class="card card-white">
                  <div class="card-body">
                    <div class="mb-4">
                      <chart />
                    </div>

                    <div class="row">
                      <div class="col-md-8 col-8">
                        <div>
                          <img
                            src="../assets/img/home/carrier.png"
                            class="img-fluid"
                          />
                          Carrier
                        </div>
                      </div>
                      <div class="col-md-4 col-4">
                        <h1 class="carrier-items">45</h1>
                      </div>
                    </div>

                    <div class="row">
                      <div
                        class="col-6 col-md-6 d-flex align-items-center m-chart-status"
                      >
                        <span
                          class="badge-circle badge-danger badge-E4E4E4"
                        ></span
                        >&nbsp; Inactive
                      </div>
                      <div
                        class="col-6 col-md-6 d-flex align-items-center m-chart-status"
                      >
                        <span
                          class="badge-circle badge-danger badge-FF754C"
                        ></span
                        >&nbsp; Under review
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div
                        class="col-6 col-md-6 d-flex align-items-center m-chart-status"
                      >
                        <span
                          class="badge-circle badge-danger badge-EBBA5B"
                        ></span
                        >&nbsp; Saved
                      </div>
                      <div
                        class="col-6 col-md-6 d-flex align-items-center m-chart-status"
                      >
                        <span
                          class="badge-circle badge-danger badge-24876E"
                        ></span
                        >&nbsp; Accepted
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mt-4 mb-6 m-display-none">
              <profile-header />
            </div>

            <div class="card card-green br-16">
              <div class="card-body">
                <div class="card card-yellow br-16">
                  <div class="card-body">
                    <div class="license">Pallet License: Enterprise</div>
                    <!--                    <p class="valid-till">-->
                    <!--                      Valid till 03 April 2025-->
                    <!--                      &nbsp;&nbsp;<i class="ni ni-bold-right"></i>-->
                    <!--                    </p>-->
                  </div>
                </div>
                <div>
                  <div class="author item-center">
                    <img
                      src="../assets/img/home/avatar.png"
                      alt="..."
                      class="avatar-xl img-raised"
                    />
                    <div class="text">
                      <span class="name invite">Invite your colleagues</span>
                      <div class="meta mt-2 d-flex">
                        <base-button
                          class="btn-theme-224034 mr-3 invite"
                          @click="showModal"
                        >
                          Invite
                        </base-button>

                        <copy-invite-link-button />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            <div class="">-->
            <!--              <div class="text-center headline-6">-->
            <!--                Activities and notifications-->
            <!--              </div>-->

            <!--              <div class="list-group-item activities-list">-->
            <!--                <activity-item-->
            <!--                  v-for="(activity, index) in activities"-->
            <!--                  :key="index"-->
            <!--                  :iconName="activity.iconName"-->
            <!--                  :activityTitle="activity.title"-->
            <!--                  :activityTime="activity.time"-->
            <!--                  :activityAuthor="activity.author"-->
            <!--                  :activityNumber="activity.number"-->
            <!--                />-->
            <!--              </div>-->

            <!--              <base-button class="btn-theme-24876E btn-width-100">-->
            <!--                See all activities-->
            <!--              </base-button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <invite-user ref="inviteUserModal" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftPanel from "../layout/LeftPanel";
import ProfileHeader from "../layout/ProfileHeader";
// import RecordCountCard from "@/views/components/RecordCountCard.vue";
// import ActivityItem from "@/views/components/ActivityItem.vue";
// import Chart from "@/views/components/Chart.vue";
import InviteUser from "@/views/InviteUser.vue";
import CopyInviteLinkButton from "@/views/components/CopyInviteLinkButton";

export default {
  bodyClass: "home",
  components: {
    LeftPanel,
    ProfileHeader,
    // RecordCountCard,
    // ActivityItem,
    // Chart,
    InviteUser,
    CopyInviteLinkButton
  },
  data: function() {
    return {
      activeMenuName: "Home",
      cardData: [
        { iconName: "carrier.png", title: "Carrier", count: 8 },
        { iconName: "teammates.png", title: "Teammates", count: 19 }
      ],
      activities: [
        {
          iconName: "avatar.png",
          title: "Quote saved",
          time: "25m",
          author: "Hakuna matata",
          number: "#6543678"
        },
        {
          iconName: "avatar-2.png",
          title: "Quote saved",
          time: "06h",
          author: "Alex white",
          number: "#6543677"
        },
        {
          iconName: "avatar-1.png",
          title: "Carrier added",
          time: "12h",
          author: "Hakuna matata",
          number: "SpeedX carrier"
        },
        {
          iconName: "avatar-4.png",
          title: "Reminder",
          time: "24h",
          author: "Your plan is expiring",
          number: "06 days left"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["userName"])
  },
  methods: {
    showModal() {
      this.$refs.inviteUserModal.openModal();
    }
  }
};
</script>

<style></style>
